<div class="breadcrumbs-row">
  <grange-breadcrumbs></grange-breadcrumbs>
</div>
<ng-container *ngIf="context | async as shopfolder">
  <div class="shopfolder-title">
    <h1>{{ shopfolder.seo_title }}</h1>
    <h2>{{ shopfolder.seo_description }}</h2>
  </div>
  <div class="search-row">
    <app-search [context]="context | async"></app-search>
  </div>
  <div class="shops-wrapper">
    <div class="shop-wrapper" *ngFor="let shop of shops">
      <a [href]="shop['@id']">
        <mat-card>
          <!-- New Mat Card Content from shopresult -->
          <!-- First Image -->
          <div class="pic-container">
            <img mat-card-image [src]="shop.image.large"/>
            <div class="centered-text">
              <h3 class="shop-name">{{ shop.name }}</h3>
              <div class="specialities-wrapper"><span class="specialities"><i>{{ shop.specialities }}</i></span></div>
            </div>
          </div>
          <mat-card-content>
            <!-- {{ shop | json }} -->
            <div class="shop-data-wrapper">
              <!-- shop ratings -->
              <div class="shop-rating">
                <div class="no-ratings-available" *ngIf="shop.ratings.rating_quantity === 0">
                  <mat-icon aria-hidden="false" aria-label="star">star</mat-icon>
                  <div class="col-9">{{ 'shopresult.newShop' | translate }}</div>
                  <mat-icon aria-hidden="false" aria-label="star">star</mat-icon>
                </div>
                <div class="shop-data" *ngIf="shop.ratings.rating_quantity > 0">
                  <mat-icon aria-hidden="false" aria-label="star">star</mat-icon>
                  <div class="ratings-col data">
                    <app-stars [rating]="shop.ratings.stars"
                               maxRating="10"
                               [quantityRatings]="shop.ratings.rating_quantity"
                               [word]="shop.ratings.word"
                               [compact]="true"
                               [path]="shop.ratings['@id']"></app-stars>
                  </div>
                </div>
              </div>
              <!-- shipping costs -->
              <div class="shop-data" *ngIf="shop.minmax_shipping_fee">
                <mat-icon aria-hidden="false" aria-label="delivery">delivery_dining</mat-icon>
                <div class="delivery-col data">
                  <div>
                    <span *ngIf="shop.minmax_shipping_fee[0] === shop.minmax_shipping_fee[1]">
                      {{ shop.minmax_shipping_fee[0] | apymCurrency:'€':'symbol':'1.2-2' }}
                    </span>
                    <span *ngIf="shop.minmax_shipping_fee[0] !== shop.minmax_shipping_fee[1]">
                      {{ 'shopresult.startingFrom' | translate: {shippingFee: shop.minmax_shipping_fee[0] | apymCurrency:'€':'symbol':'1.2-2' } }}
                    </span>
                  </div>
                  <div class="free-delivery-offer" *ngIf="shop.minimum_order_without_shipping_costs">
                    {{ 'shopresult.moreThanXFree' | translate: {minAmount: shop.minimum_amount_without_shipping_costs | apymCurrency:'€':'symbol':'1.2-2'} }}
                  </div>
                </div>
              </div>
              <!-- Minimum Delivery Price -->
              <div class="shop-data">
                <mat-icon aria-hidden="false">euro_symbol</mat-icon>
                <div class="data">
                  Min. <span *ngIf="shop.minimum_amount_delivery_order">{{ shop.minimum_amount_delivery_order | apymCurrency:'€':'symbol':'1.2-2' }}</span>
                </div>
              </div>
              <!-- Main City -->
              <div class="shop-data">
                <mat-icon aria-hidden="false">map</mat-icon>
                <div class="data">
                  <span>{{ shop.first_city }}</span>
                </div>
              </div>
            </div>
            <div class="shop-summary" [innerHTML]="shop.description"></div>
            <div class="shop-timetable">
              <h5>{{ 'shopfolder.deliveryTimetableTitle' | translate }}</h5>
              <apym-timetable caption="{{ 'shopfolder.deliveryTimetable' | translate }}" [timetable]="shop.delivery_week_timetable"></apym-timetable>
            </div>
          </mat-card-content>
        </mat-card>
      </a>
    </div>
  </div>
</ng-container>
